import React from "react";
import "typeface-inter"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme";
import { wrapper } from "../components/defaults/styles/elements";
import { css } from "@emotion/core";
import PressFooter from "../components/pressfooter"
import { TitleHeader, PeopleDisclaimer } from "../components/defaults/styles/elements"
import { People as PeopleIcon } from "../components/defaults/assets/svg/mobilenav"
import { ExpCardList } from '../components/main/expcard';
import SubNav from '../components/main/sub_nav';
import PeopleCard from '../components/main/expcard-people';
import { ExpCardListItem } from '../components/main/expcard';
import Pagination from '../components/pagination';


const seo = {
  frontmatter: {
    title: "People",
  },
}

const nav_data = [
  {
    name: "All People",
    url: "/people/all"
  },
  {
    name: "Speakers",
    url: "/people/speakers"
  },
  {
    name: "Corporate Champions",
    url: "/people/champions"
  },
  {
    name: "EXPO Management",
    url: "/people/ops"
  },
]


const PeopleCardGroup = ({ data }) => {
  // the idea is to first group by Partner, then sort according to belows "partnerOrder" array
  // and afterwards access the respective employees which have been sorted by graphql based on their surname
  const partnerOrder = [
    "Daimler",
    "Plug and Play",
    "ARENA2036",
    "Universität Stuttgart",
    "DXC",
    "ZF",
    "BASF",
    "Porsche",
    "DPDHL",
    "Webasto",
    "Rolls-Royce Power Systems",
    "Motherson",
    "T-Systems",
    "Wabco",
    "Bosch",
    "Murata",
    "AGC",
    "Linde",
    "Jardines",
    "Wieland",
    "Covéa",
    "BP",
    "TÜV",
    "Hyundai",
    "Yanfeng",
    "Bleistahl Produktions GmbH & Co. KG",
    "Eberspächer",
    "Poste Italiane",
    "Faurecia",
    "Sekisui s-lec",
    "ADAC",
  ]
  
  let orderedPartners = []
  let index = null
  
  // access the array which includes the groups
  data.allPeople.group.map(({edges, fieldValue}) => {
    // get the position which the accessed group would have in the partnerOrder array
    index = partnerOrder.indexOf(fieldValue)
    
    
    if ( index > orderedPartners.length ) {
      // if the calculated position is beyond the new arrays length create as much empty items as possible to
      // position the company at the right spot
      let diff = index - orderedPartners.length
      Array.from({ length: diff }).forEach(() => {
        orderedPartners.push(undefined)
      })
      orderedPartners.push(edges)
    } else {
      // if not cut the respective position and place the company item there
      orderedPartners.splice(index, 1, edges)
    }

    return undefined;
  
  })
  
  // remove all undefined elements in the array (as there might happen some arrows otherwise)
  Object.keys(orderedPartners).forEach(key => orderedPartners[key] === undefined ? delete orderedPartners[key] : {})
  
  return orderedPartners.map(group =>
    group.map(({ node }, index) => <PeopleCard data={node} key={index} />)
  )
}


const People = ({ data, pageContext }) => {
  const { numPages, currentPage, champions, basePage, dedicated_comp, id_based } = pageContext
  
  return (
    <Layout seo={seo}>
      <TitleHeader
        title={
          dedicated_comp
            ? `Attendees of ` + data.company.data.name
            : `The People`
        }
        subtitle="<p>We have an expansive network of innovators all around the world that make up our community - founders, industry experts, entrepreneurs, investors, artists, doctors, students, and everything in between. Part of what we love so much about our events is having the opportunity to come together from all walks of life to exchange ideas on topics we are passionate about.</p>"
        icon={<PeopleIcon />}
      />

      <div css={wrapper}>
        <SubNav nav_data={nav_data} />
      </div>

      <section
        css={css`
          background: ${color.main_light};
          position: relative;
          margin-top: -2.5rem;
        `}
      >
        <ExpCardList>
          {champions ? (
            <PeopleCardGroup data={data} />
          ) : dedicated_comp || id_based ? (
            data.employees.edges !== undefined &&
            data.employees.edges.length !== 0 ? (
              data.employees.edges.map(({ node }, index) => (
                <PeopleCard data={node} key={index} />
              ))
            ) : (
              ""
            )
          ) : (
            data.allPeople.edges.map(({ node }, index) => (
              <PeopleCard data={node} key={index} />
            ))
          )}

          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
          <ExpCardListItem placeholder_item></ExpCardListItem>
        </ExpCardList>

        <div css={wrapper}>
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            basePage={basePage}
          />
        </div>

        <div css={wrapper}>
          <PeopleDisclaimer
            css={css`
              text-align: center;
              margin: 0 auto;
              padding-left: 1em;
              padding-right: 1em;
            `}
          />
        </div>
      </section>
      <PressFooter theme={color.main_light} />
    </Layout>
  )
}

export const query = graphql`
         query People(
           $skip: Int!
           $limit: Int!
           $group: [String!]
           $sort: [AirtableFieldsEnum]
           $order: [SortOrderEnum]
           $champions: Boolean!
           $dedicated_comp: Boolean!
           $id_based: Boolean! = false
           $airtable_id: String
           $users: [String]
         ) {
           allPeople: allAirtable(
             filter: {
               table: { eq: "people" }
               data: {
                 group: { in: $group }
                 include: { eq: true }
                 consent: { eq: true }
               }
             }
             sort: { fields: $sort, order: $order }
             limit: $limit
             skip: $skip
           ) @skip(if: $id_based) {
             edges @skip(if: $champions) {
               node {
                 recordId
                 id
                 data {
                   manual_sorting
                   name
                   image {
                     localFiles {
                       childImageSharp {
                         fluid {
                           ...GatsbyImageSharpFluid_withWebp_tracedSVG
                         }
                       }
                     }
                   }
                   partner_company {
                     data {
                       name
                     }
                   }
                   startup_company {
                     data {
                       startup
                     }
                   }
                   custom_company
                   profession
                 }
               }
             }
             group(field: data___partner_company___data___name)
               @include(if: $champions) {
               edges {
                 node {
                   id
                   data {
                     name
                     image {
                       localFiles {
                         childImageSharp {
                           fluid {
                             ...GatsbyImageSharpFluid_withWebp_tracedSVG
                           }
                         }
                       }
                     }
                     partner_company {
                       data {
                         name
                       }
                     }
                     profession
                   }
                 }
               }
               fieldValue
             }
           }
           company: airtable(
             table: { eq: "partners" }
             id: { eq: $airtable_id }
           ) @include(if: $dedicated_comp) {
             data {
               name
             }
           }
           employees: allAirtable(
             filter: {
               table: { eq: "people" }
               id: { in: $users }
               data: { include: { eq: true }, consent: { eq: true } }
             }
             sort: { fields: $sort, order: $order }
             limit: $limit
             skip: $skip
           ) @include(if: $id_based) {
             edges {
               node {
                 id
                 data {
                   name
                   profession
                   partner_company {
                     data {
                       name
                     }
                   }
                   startup_company {
                     data {
                       startup
                     }
                   }
                   custom_company
                   image {
                     localFiles {
                       childImageSharp {
                         fluid {
                           ...GatsbyImageSharpFluid_withWebp_tracedSVG
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `



export default People;

