import React from 'react'; 
import { css } from '@emotion/core';
import BackgroundImage from 'gatsby-background-image-es5';
import mq from "../defaults/styles/mediaquerys"
import { wrapper } from "../defaults/styles/elements"
import Link from '../link';
import { color } from '../../components/defaults/styles/theme';

// exp for exposure

export const ExpCardList = ({className, data, children}) => {
    return (
      <ul
        className={className}
        css={css`
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0;
          margin: 0;
          ${wrapper};
          padding-top: 5rem;
          padding-bottom: 2rem;
          /* max-width: 23rem; */
        `}
      >
          { children }
      </ul>
    )
};


export const ExpCardListItem = ({ children, ...props }) => {
  return (
    <li
      key={props.key}
      css={css`
        flex: 0 0 calc(100% / 2 - .55em);
        margin-bottom: ${props.placeholder_item ? "0" : "2rem"};
        min-width: 0;
        ${mq[0]} {
            flex: 0 0 calc(100% / 3 - .55em)
        }
        ${mq[1]} {
            flex: 0 0 calc(100% / 4 - .55em)
        }
        ${mq[2]} {
            flex: 0 0 calc(100% / 5 - .75em)
        }
        ${mq[3]} {
            flex: 0 0 calc(100% / 5 - 1em)
        }
      `}
    >
      {children}
    </li>
  )
}

export const ExpCardLinkWrapper = ({children, className, id, active}) => {
  return (
    active === "true" ? (
      <Link
        to={"/people/" + id}
        className={className}
        css="">
        { children }
      </Link>
    ) : (
      children
    )
  )
};



export const ExpCard = ({ children, className }) => {
    return (
        <div className={className} css={css`
            position: relative;
        `}>
            { children }
        </div>
    )
};

export const ExpCard__Square = ({data, children}) => {
    return (
      <div
        css={css`
          width: 100%;
          position: relative;
          background: ${color.main_dark};
          border-radius: 5px;
          overflow: hidden;
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        `}
      >
          { children }
      </div>
    )
}

export const ExpCard__Media = ({ img, children }) => {
    
    return (
      <BackgroundImage
        fluid={img[0].childImageSharp.fluid}
        style={{ position: "absolute" }}
        css={css`
            width: 100%;
            height: 100%;
        `}
      >
        {children}
      </BackgroundImage>
    )
};

export const ExpCard__Square_Inner = ({data, children, className}) => {
    return (
      <div
        className={className}
        css={css`
          height: 100%;
          width: 100%;
          background: linear-gradient(
            to bottom,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.001) 0.7%,
            hsla(0, 0%, 0%, 0.006) 2.8%,
            hsla(0, 0%, 0%, 0.016) 6.2%,
            hsla(0, 0%, 0%, 0.031) 10.7%,
            hsla(0, 0%, 0%, 0.055) 16.1%,
            hsla(0, 0%, 0%, 0.087) 22.5%,
            hsla(0, 0%, 0%, 0.13) 29.7%,
            hsla(0, 0%, 0%, 0.184) 37.5%,
            hsla(0, 0%, 0%, 0.251) 45.8%,
            hsla(0, 0%, 0%, 0.332) 54.5%,
            hsla(0, 0%, 0%, 0.429) 63.5%,
            hsla(0, 0%, 0%, 0.543) 72.7%,
            hsla(0, 0%, 0%, 0.675) 81.9%,
            hsla(0, 0%, 0%, 0.827) 91.1%,
            hsl(0, 0%, 0%) 100%
          );
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-end;
          padding: 0 1em 0.85em;
        `}
      >
        {children}
      </div>
    )
};


export const ExpCard__Title = ({data}) => {
    return (
      <div
        css={css`
          margin-bottom: 0.5em;
        `}
      >
        <h3
          css={css`
            font-size: 0.85em;
            line-height: 1.4em;
            color: white;
          `}
        >
          {data}
        </h3>
      </div>
    )
};



export const ExpCard__Addendum = ({className, data, children}) => {
    return (
      <div
        css={css`
          padding: 0.25em 0em 0;
          /* display: flex;
          flex-direction: column; */
          /* min-width: 0; */
          /* flex-basis: 100%; */
          max-width: 100%;
          p {
            font-size: 0.65em;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 1.6em;
            color: white;
            /* min-width: 0; */
          }
        `}
      >
        {children}
      </div>
    )
};


export const ExpCard__Addendum_Main = ({className, data}) => {
    return (
      <div
        css={css`
        `}
      >
        <p>{data}</p>
      </div>
    )
};

export const ExpCard__Addendum_Sub = ({className, data}) => {
    return (
      <div>
        <p
          css={css`
            font-weight: 700;
          `}
        >
          {data}
        </p>
      </div>
    )
};



